<template>
  <div>
    <div class="card p-2 bg-card">
      <div class="align-center-flex">
        <div>
          <input
            v-model="searchResult"
            placeholder="Search"
            class="mr-bottom form-control input-sm flex"
          />
        </div>
        <div>
          <button
            @click="addUser()"
            class="btn pointer btn-primary btn-sm margin-right"
            :disabled="
              !this.$store.state.auth.permissions.includes('user-store')
            "
          >
            + Add
          </button>
        </div>
      </div>
    </div>
    <b-card>
      <div class="user-select">
        <div class="card-company-table card card-congratulation-medal">
          <div class="mb-0 table-responsive border rounded">
            <table role="table" class="table table-hover">
              <thead role="rowgroup header">
                <tr role="row">
                  <th role="columnheader" scope="col">#</th>
                  <th role="columnheader" scope="col">First Name</th>
                  <th role="columnheader" scope="col">Last Name</th>
                  <th role="columnheader" scope="col">Email</th>
                  <th role="columnheader" scope="col">Role</th>
                  <!-- <th role="columnheader" scope="col">Designation</th> -->

                  <th role="columnheader" scope="col" class="text-align-right">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="rowgroup">
                <tr
                  v-for="(user, index) in allUsers"
                  :key="user._id"
                  role="row"
                  class="custom-table-row"
                >
                  <td role="cell">
                    {{ (currentPage - 1) * perPage + index + 1 }}
                  </td>
                  <td role="cell">{{ user.firstname }}</td>
                  <td role="cell">{{ user.lastname }}</td>
                  <td role="cell">{{ user.email }}</td>
                  <td role="cell">
                    <span v-if="!user.roles == ''">
                      <span
                        v-for="role in user.roles"
                        :key="role"
                        class="d-flex align-items-center"
                        >{{ role.name }}
                        <span class="icon-box-new-user pointer">
                          <feather-icon
                            class="rotate"
                            icon="EditIcon"
                            size="20"
                            @click="addRole(user._id)"
                          /> </span
                      ></span>
                    </span>
                    <span v-if="user.roles == ''">
                      <button
                        style="
                          border: 2px dotted #968df3;
                          border-radius: 50%;
                          width: 25px;
                          height: 25px;
                        "
                        @click="addRole(user._id)"
                      >
                        +
                      </button>
                    </span>
                  </td>

                  <td
                    role="cell"
                    class="align-center flex-display text-align-right"
                  >
                    <span class="icon-box-new-user pointer">
                      <feather-icon
                        class="rotate"
                        icon="EditIcon"
                        size="20"
                        @click="editUser(user._id)"
                      />
                    </span>
                    <!-- <span
                      @click="showModal(user._id, user)"
                      class="icon-box-new-user pointer"
                    >
                      <feather-icon
                        class="delete-color rotate"
                        icon="Trash2Icon"
                        size="20"
                      />
                    </span> -->
                    <button
                      class="btn btn-primary btn-sm"
                      @click="inviteUser(user._id, user.user_type)"
                    >
                      Invite
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="card-body">
              <b-modal
                id="modal-danger"
                ref="modal-danger"
                ok-only
                ok-variant="danger"
                @ok="deleteUser()"
                modal-class="modal-danger"
                centered
                title="Delete !"
                :ok-disabled="
                  !this.$store.state.auth.permissions.includes('user-delete')
                "
              >
                <b-card-text>
                  Are You Sure to Delete the {{ user_detail.firstname }}
                  {{ user_detail.lastname }} ?
                  <!-- {{ user_detail }} -->
                </b-card-text>
              </b-modal>
            </div>
          </div>
        </div>
        <div class="align-center-div">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="lastPage * perPage"
            class="float-right"
          />
        </div>
      </div>
    </b-card>
    <b-sidebar
      id="add-sub"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="addconsultant"
      lazy
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <template>
            <b-form-group label="First Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="First Name"
                  v-model="fname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Last Name"
                  v-model="lname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Phone Number" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Phone number"
                rules="required|integer"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Phone Number"
                  v-model="phonenumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="should_invite">
                Send invitation to mail
              </b-form-checkbox>
            </b-form-group>

            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="validationFormsub"
            >
              Add User
            </button>
          </template>

          <button class="btn btn-secondary mt-1" @click="closeAdduser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-sidebar
      id="add-sub"
      bg-variant="white"
      right
      backdrop
      shadow
      width="640px"
      v-model="editconsultant"
    >
      <div class="p-2">
        <validation-observer ref="observer">
          <b-form-group label="Email" label-for="basicInput">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="basicInput"
                placeholder="Email"
                v-model="emailid"
                :disabled="true"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <template>
            <b-form-group label="First Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="First Name"
                  v-model="fname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Last Name"
                  v-model="lname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Phone Number" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Phone number"
                rules="required|integer"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Phone Number"
                  v-model="phonenumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <button
              class="btn btn-primary mt-1 mr-1"
              @click="updateUser"
              :disabled="
                !this.$store.state.auth.permissions.includes('user-update')
              "
            >
              Update User
            </button>
          </template>

          <button class="btn btn-secondary mt-1" @click="closeEdituser()">
            Cancel
          </button>
        </validation-observer>
      </div>
    </b-sidebar>
    <b-modal ref="modal-addrole" title="Assign a Role " hide-footer>
      <label for="">Role</label>
      <select name="" id="" v-model="selectedrole" class="form-control">
        <option :value="role._id" v-for="role in allRoles">
          {{ role.name }}
        </option>
      </select>
      <!-- {{assignusers}}
        {{selectedconsultant}} -->

      <button class="btn btn-primary btn-sm mt-1" @click="assignRole()">
        Assign Role
      </button>
    </b-modal>
    <b-modal ref="modal-invite" title="Copy Invite " hide-footer>
     
      <!-- {{assignusers}}
        {{selectedconsultant}} -->
      <div v-if="genLink">
        <input
          type="text"
          class="form-control mt-1"
          v-model="genLink"
          ref="invite-link"
        />

        <p class="text-success">
          Link Generated Successfully ! Please click below button to copy the
          link
        </p>
      </div>

      <button class="btn btn-primary btn-sm mt-1" @click="copylink()" :disabled="genLink == ''">
        Copy Link
      </button>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BPagination,
  BModal,
  BCardText,
  BCard,
  BSidebar,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
export default {
  data: () => ({
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    rows: 1,
    allUsers: [],
    userId: "",
    searchResult: "",
    addconsultant: false,
    emailid: "",
    fname: "",
    lname: "",
    phonenumber: "",
    should_invite: "",
    required,
    email,
    userdetails: [],
    editconsultant: false,
    portaluserid: "",
    allRoles: [],
    selectedrole: "",
    user_detail: [],
    userInviteId: "",
    selectedApp: "",
    genLink: "",
    userInviteType: "",
  }),
  components: {
    BModal,
    BPagination,
    BCardText,
    BCard,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
  },

  props: {},
  watch: {
    currentPage(value) {
      this.getUser(value);
    },
    searchResult() {
      this.getUser(this.currentPage);
    },
  },
  mounted() {
    this.getUser(this.currentPage);
  },
  computed: {},
  methods: {
    copylink() {
      const element = this.$refs["invite-link"];
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Link Copied !",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    async closeAdduser() {
      this.addconsultant = false;
      this.emailid = "";
      this.fname = "";
      this.lname = "";
      this.phonenumber = "";
      this.$nextTick(() => this.$refs.observer.reset());
    },
    inviteUser(userid, usertype) {
     
      this.userInviteId = userid;
      this.userInviteType = usertype;
      this.generateInviteLink();
    },
    generateInviteLink() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/reinvite-link?user_id=${this.userInviteId}&redirect_to=portal`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
          this.genLink = res.data.data.invite_link;
           this.$refs["modal-invite"].show();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
              component: ToastificationContent,
              props: {
                title: err.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });

        });
    },
    closeEdituser() {
      this.editconsultant = false;
    },
    addRole(userid) {
      this.portaluserid = userid;
      this.getRoles();
      this.$refs["modal-addrole"].show();
    },
    getRoles() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/roles`,
      };
      this.$http(options)
        .then((res) => {
          
          this.allRoles = res.data.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: "EditIcon",
                variant: "danger",
              },
            });
        });
    },
    assignRole() {
      const data = {};
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/${this.portaluserid}/assign-role/${this.selectedrole}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$refs["modal-addrole"].hide();
            this.getUser(this.currentPage);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User Updated",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validationFormsub() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.addPortalUser();
        }
      });
    },
    editPortalUser(userid) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/show?user_id=${userid}`,
      };

      this.$http(options)
        .then((res) => {
          this.userdetails = res.data.data.data;
          this.emailid = res.data.data.email;
          this.fname = res.data.data.firstname;
          this.lname = res.data.data.lastname;
          this.phonenumber = res.data.data.mobile;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUser() {
      const data = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        user_type: "Portal_user",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/user/update?user_id=${this.portaluserid}`,
      };
      this.$http(options)
        .then((res) => {
          this.editconsultant = false;
          this.getUser(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User Updated",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addPortalUser() {
      const data = {
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        user_type: "Portal_user",
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/add`,
      };
      this.$http(options)
        .then((res) => {
          this.addconsultant = false;
          this.fname = "";
          this.lname = "";
          this.emailid = "";
          this.phonenumber = "";
          this.getUser(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.addusertotenant = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "User added",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.errors.email[0],
              icon: "EditIcon",
              variant: "success",
            },
          });
        });
    },
    searchResultData(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/users?name=${this.searchResult}`,
          params: { page: num, limit: this.perPage },
        };

        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addUser() {
      // this.$router.push({ name: "adduser" });
      this.addconsultant = true;
      this.fname = "";
      this.lname = "";
      this.emailid = "";
      this.phonenumber = "";
    },
    editUser(uid) {
      this.portaluserid = uid;
      this.editPortalUser(this.portaluserid);
      this.editconsultant = true;
    },
    viewDetail(id) {
      this.$router.push({ name: "userdetail", params: { id: id } });
    },
    getUser(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_SSO_API_URL +
            `/user/get?user_type=Portal_user&search=${this.searchResult}`,
          params: { page: num, limit: this.perPage },
        };
        this.$http(options)
          .then((res) => {
            this.allUsers = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.perPage = res.data.data.per_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteUser(userid) {
      userid = this.userId;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/user/delete?user_id=${userid}`,
      };
      this.$http(options)
        .then((res) => {
          this.getUser();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showModal(itemid, user) {
      this.userId = itemid;
      this.user_detail = user;
      this.$refs["modal-danger"].show();
    },
  },
};
</script>

<style>
.header {
  font-size: 20px;
  font-weight: 600;
}

.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-20 {
  padding: 20px;
}
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
/* .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7 !important;
  color: #6e6b7b;
} */

.align-right {
  display: flex;
  justify-content: flex-end;
}
.user-select {
  user-select: none;
}
.icon-box-new-user {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}

.mr-bottom {
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;
}
.delete-color {
  color: #ea5455 !important;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}

.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}

.rowgroup tr:hover {
  cursor: pointer !important;
}

.text-align-right {
  text-align: right;
}

.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
